/* @flow */
import React, { useState, useEffect, lazy } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../config/firebase';
import UserService from '../services/UserService';
import { useLoading } from '../components/Common/LoadingContext';

const Header = lazy(() => import('../components/Header'));
const Home = lazy(() => import('../pages/Home'));
const SignUp = lazy(() => import('../pages/SignUp'));
const Onboarding = lazy(() => import('../pages/Onboarding'));
const Login = lazy(() => import('../pages/Login'));
const Profile = lazy(() => import('../pages/Profile'));
const NotFound = lazy(() => import('../pages/NotFound'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const Locations = lazy(() => import('../pages/Locations'));
const Footer = lazy(() => import('../components/Footer'));
const TermsOfService = lazy(() => import('../pages/TermsOfService'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));

const RouteConfig = (props) => {

  const [user, setUser] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        const response = await UserService.getUserProfile();
        setUserInfo(response.data);
        setIsOnboarding(response.data.onboarding)
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    const authUnsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser({
          email: user.email,
          phoneNumber: user.phoneNumber || ''
        })
        fetchUserProfile();
        setIsAuthenticated(true);
      } else {
        setUser({})
        setUserInfo({})
        setIsAuthenticated(false);
      }
    });
    return () => authUnsubscribe();
  }, [auth]);
  
  return (
    <BrowserRouter>
      <div className="app-container bg-light">
        <Header isAuthenticated={isAuthenticated} />
          <Routes>
            <Route path="/" element={<Home />} />
            {isAuthenticated ? (
              <>
                <Route path="/profile" element={isOnboarding ? (
                    <Navigate to="/onboarding" />
                  ) : (
                    <Profile user={user} userInfo={userInfo} />
                  )} />
                <Route path="/onboarding" element={<Onboarding />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/reset-password" element={<ResetPassword />} />
              </>
            )}
            
            <Route path="/locations/global-entry" element={<Locations appointmentType={'Global Entry'} />} />
            <Route path="/locations/nexus" element={<Locations appointmentType={'NEXUS'} />} />
            <Route path="/locations/sentri" element={<Locations appointmentType={'SENTRI'} />} />

            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            
            <Route path="*" element={<NotFound />} />
          </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default RouteConfig;
