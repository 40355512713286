import axios from 'axios';
import { auth } from '../config/firebase';
import { API_URL } from '../config/constants';

class UserService {
  
  async request(url, method, data = null) {
    try {
      const accessToken = await auth.currentUser.getIdToken();
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${accessToken}`,
      };
      const config = { url, method, headers, data };
      return await axios.request(config);
    } catch (err) {
      throw err;
    }
  }

  async getUserProfile() {
    try {
      return await this.request(`${API_URL}/profile`, 'GET');
    } catch (err) {
      throw err;
    }
  }

  async updateProfile(updatedUserInfo) {
    try {
      return await this.request(`${API_URL}/profile`, 'PUT', updatedUserInfo);
    } catch (err) {
      throw err;
    }
  }

  async updatePhoneNumber(phoneNumber) {
    try {
      return await this.request(`${API_URL}/phone-number`, 'PUT', { phoneNumber });
    } catch (err) {
      throw err;
    }
  }

  async getCheckoutSession() {
    try {
      return await this.request(`${API_URL}/checkout`, 'POST');
    } catch (err) {
      throw err;
    }
  }
}

export default new UserService();
