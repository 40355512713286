import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

import { FallbackLoading } from './components/Common/FallbackLoading';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<FallbackLoading />}>
      <App />
    </Suspense>
);

reportWebVitals();
