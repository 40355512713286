import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { 
  FIREBASE_KEY,
  FIREBASE_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_FUNCTION_EMULATOR_PORT,
  FIREBASE_FIRESTORE_EMULATOR_PORT,
  FIREBASE_AUTH_EMULATOR_PORT
} from "./constants";

const firebaseConfig = {
  apiKey: FIREBASE_KEY,
  authDomain: FIREBASE_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);

export const auth = getAuth(app);

if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "127.0.0.1", parseInt(FIREBASE_FUNCTION_EMULATOR_PORT));
  connectFirestoreEmulator(db, '127.0.0.1', parseInt(FIREBASE_FIRESTORE_EMULATOR_PORT));
  connectAuthEmulator(auth, `http://127.0.0.1:${FIREBASE_AUTH_EMULATOR_PORT}`);
}

export const googleProvider = new GoogleAuthProvider()

export default app;
