export const API_URL = process.env.REACT_APP_API_URL;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC;
export const FIREBASE_KEY = process.env.REACT_APP_FIREBASE_KEY;
export const FIREBASE_DOMAIN = process.env.REACT_APP_FIREBASE_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_FUNCTION_EMULATOR_PORT = process.env.REACT_APP_FIREBASE_FUNCTION_EMULATOR_PORT;
export const FIREBASE_FIRESTORE_EMULATOR_PORT = process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_PORT;
export const FIREBASE_AUTH_EMULATOR_PORT = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_PORT;

export const StatusCodes = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    INTERNAL_SERVER_ERROR: 500,
    NOT_FOUND: 404,
    FORBIDDEN: 403,
};

export const API = {
    users: {
      create: `/users`,
      update: '/profile',
      getUserInfo: `/profile`,
      getCheckoutSession: `/checkout`
    },
    locations: {
      get: '/locations'
    },
    waittimes: {
      get: '/wait-times'
    }
}
